import "./section.styles.scss";

import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
} from "@mui/material";
// import WhoAreWe from "Components/WhoAreWe/WhoAreWe";
import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

const VIDEO_LINKS = [
  "https://vimeo.com/1054191544/d01f6c1dc9",
  "https://vimeo.com/1054191868/67ff0a617d",
  "https://vimeo.com/1054335644/3d1e0b3be9",
];
const GROUP_IMAGE =
  "https://kewordal-images.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/Group+2.webp";
const AVATAR_IMAGE =
  "https://kewordal-images.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/user-avatar.webp";

const AUTOPLAY_SPEED = 8000;

const Section1 = () => {
  // ref
  const slideRef = useRef(null);
  const timerRef = useRef(null);

  // state
  const [activeSlide, setActiveSlide] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isVideoHovered, setIsVideoHovered] = useState(false);
  const [ringValue1, setRingValue1] = useState(0);
  const [ringValue2, setRingValue2] = useState(0);
  const [ringValue3, setRingValue3] = useState(0);

  // router
  const navigate = useNavigate();

  useEffect(() => {
    timerRef.current = setInterval(() => {
      if (!isVideoHovered) {
        setProgress((prevProgress) => {
          if (prevProgress === 100) {
            setActiveSlide((prevRing) => {
              const newSlide = (prevRing + 1) % 3;
              slideRef.current.slickGoTo(newSlide);
              return newSlide;
            });
            return 0;
          } else {
            return prevProgress + 5;
          }
        });
      }
    }, 1000);

    return () => {
      clearInterval(timerRef.current);
    };
  }, [isVideoHovered]);

  const handleJoin = () => {
    navigate("/register");
  };

  const handleVideoProgress = (data, slideIndex) => {
    const { played } = data;
    const playedPercent = Number((played * 100).toFixed(2));
    switch (slideIndex) {
      case 0: {
        setRingValue1(playedPercent);
        break;
      }
      case 1: {
        setRingValue2(playedPercent);
        break;
      }
      case 2: {
        setRingValue3(playedPercent);
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleVideoSelectPlay = (slideIndex) => {
    setActiveSlide(slideIndex);
    slideRef.current.slickGoTo(slideIndex);
  };

  const handleVideoEnd = () => {
    switch (activeSlide) {
      case 0: {
        setActiveSlide(1);
        slideRef.current.slickNext();
        break;
      }
      case 1: {
        setActiveSlide(2);
        slideRef.current.slickNext();
        break;
      }
      case 2: {
        setActiveSlide(0);
        slideRef.current.slickNext();
        break;
      }
      default: {
        break;
      }
    }
  };

  const handleVideoHover = () => {
    setIsVideoHovered(true);
  };

  const handleVideoLeave = () => {
    setIsVideoHovered(false);
  };

  const settings = {
    className: "slider variable-width",
    centerMode: true,
    infinite: false,
    centerPadding: "60px",
    autoplaySpeed: AUTOPLAY_SPEED,
    autoplay: false,
    dots: false,
    pauseOnHover: true,
    pauseOnDotsHover: true,
    slidesToShow: 1,
    speed: 500,
    focusOnSelect: false,
    variableWidth: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          infinite: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          variableWidth: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: false,
          variableWidth: false,
        },
      },
    ],
    afterChange: (current) => {
      setProgress(0);
      setActiveSlide(current);
    },
  };

  return (
    <div className="section-1">
      <Container maxWidth="xl">
        <Grid container spacing={3}>
          <Grid xs={12} item>
            <div className="mb-4">
              <h1
                hidden
                className="black text-center"
                style={{ fontSize: "1.7rem", fontWeight: "400" }}
              >
                Kewordal is a platform that combines social, education, revision
                and learning. Join us to connect, learn, share and grow.
              </h1>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <Typography
                component="p"
                className="blue-text"
                sx={{
                  fontSize: { xs: "1rem", md: "1.4rem" },
                  fontWeight: 600,
                }}
              >
                University, College, High School Students
              </Typography>
              <Typography
                className="large-bold-text"
                sx={{
                  fontSize: {
                    xs: "3rem",
                    md: "4.9rem",
                  },
                }}
              >
                Your Key to
              </Typography>
              <Typography
                sx={{
                  mt: { xs: "-0.2rem", md: "-1.5rem" },
                  fontSize: {
                    xs: "3rem",
                    md: "4.9rem",
                  },
                }}
                className="large-bold-text"
              >
                Academic
              </Typography>
              <Typography
                sx={{
                  mt: { xs: "-0.2rem", md: "-1.5rem" },
                  fontSize: {
                    xs: "3rem",
                    md: "4.9rem",
                  },
                }}
                className="large-bold-text"
              >
                <span className="yellow-text">Success</span>
              </Typography>
              <p style={{ fontSize: "1.2rem", marginTop: "0.7rem" }}>
                Kewordal is an intuitive revision, social education, and
                <br />
                learning platform, that enables students to attain
                <br />
                higher grades in exams and tests.
              </p>
              <div>
                <Button
                  size="large"
                  variant="contained"
                  onClick={handleJoin}
                  sx={{
                    marginTop: 1,
                    borderRadius: "9px",
                    backgroundColor: "#8AC246",
                    mb: 4,
                    "&:hover": {
                      backgroundColor: "#8AC246",
                    },
                  }}
                >
                  Join Now
                </Button>
              </div>
              <p style={{ fontSize: "0.95rem", marginTop: "0.7rem" }}>
                Our learning method is trusted by millions of students worldwide
              </p>
              <img
                src={AVATAR_IMAGE}
                height="50px"
                alt="group_image"
                loading="lazy"
              />
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Box
              // className="image-box"
              sx={{
                minHeight: {
                  xs: "400px",
                  md: "700px",
                },
                borderRadius: "7px",
                backgroundImage: `url("${GROUP_IMAGE}")`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            />
          </Grid>
        </Grid>
      </Container>
      <Container sx={{ mt: "4rem" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: {
              xs: "3rem",
              md: "11rem",
            },
            marginBottom: "4rem",
          }}
          className="color-boxes"
        >
          <div
            onClick={() => handleVideoSelectPlay(0)}
            className="color-div-wrapper"
          >
            {activeSlide === 0 && (
              <CircularProgress
                variant="determinate"
                size={79}
                sx={{ position: "absolute", color: "#8ac246" }}
                // value={ringValue1}
                value={activeSlide === 0 ? progress : 0}
              />
            )}
            <div className={"color-div green-box"} />
          </div>
          <div
            onClick={() => handleVideoSelectPlay(1)}
            className="color-div-wrapper"
          >
            {activeSlide === 1 && (
              <CircularProgress
                variant="determinate"
                size={79}
                sx={{ position: "absolute" }}
                // value={ringValue2}
                value={activeSlide === 1 ? progress : 0}
              />
            )}
            <div className="color-div blue-box" />
          </div>
          <div
            onClick={() => handleVideoSelectPlay(2)}
            className="color-div-wrapper"
          >
            {activeSlide === 2 && (
              <CircularProgress
                variant="determinate"
                size={79}
                sx={{ position: "absolute", color: "#fec004" }}
                // value={ringValue3}
                value={activeSlide === 2 ? progress : 0}
              />
            )}
            <div className="color-div yellow-box" />
          </div>
        </Box>
      </Container>
      <Container maxWidth="xl">
        <Slider {...settings} ref={slideRef}>
          <div>
            <CustomSlides
              videoURL={VIDEO_LINKS[0]}
              title="Revision"
              description="that enables learners to retain information for longer and achieve better academic results"
              isActive={activeSlide === 0}
              slideIndex={0}
              handleVideoEnd={handleVideoEnd}
              handleVideoProgress={handleVideoProgress}
              handleVideoHover={handleVideoHover}
              handleVideoLeave={handleVideoLeave}
            />
          </div>
          <div>
            <CustomSlides
              videoURL={VIDEO_LINKS[1]}
              title="Social"
              description="algorithm that connect learners to passive educators to improve academic attainment and personal development"
              isActive={activeSlide === 1}
              slideIndex={1}
              handleVideoEnd={handleVideoEnd}
              handleVideoProgress={handleVideoProgress}
              handleVideoHover={handleVideoHover}
              handleVideoLeave={handleVideoLeave}
            />
          </div>
          <div>
            <CustomSlides
              videoURL={VIDEO_LINKS[2]}
              title="Learning"
              description="that helps users reach their full potential and passive educators to achieve self-actualisation"
              isActive={activeSlide === 2}
              slideIndex={2}
              handleVideoEnd={handleVideoEnd}
              handleVideoProgress={handleVideoProgress}
              handleVideoHover={handleVideoHover}
              handleVideoLeave={handleVideoLeave}
              isLight
            />
          </div>
        </Slider>
      </Container>
    </div>
  );
};

const CustomSlides = ({
  videoURL,
  title,
  slideIndex,
  description,
  isActive,
  handleVideoEnd,
  handleVideoProgress,
  handleVideoHover,
  handleVideoLeave,
  isLight = false,
}) => {
  return (
    <Box
      sx={{
        marginRight: "1.7rem",
        opacity: isActive ? 1 : 0.4,
        borderRadius: "7px",
        overflow: "hidden",
        maxWidth: "500px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <Typography
          sx={{ fontWeight: 600, fontSize: { xs: "1.5rem", md: "2.7rem" } }}
        >
          {title}
        </Typography>
        <Typography
          sx={{ fontSize: "0.85rem", mb: "1.5rem" }}
          className="px-5 text-center"
        >
          {description}
        </Typography>
      </Box>
      <div onMouseEnter={handleVideoHover} onMouseLeave={handleVideoLeave}>
        <ReactPlayer
          onEnded={handleVideoEnd}
          onProgress={(data) => handleVideoProgress(data, slideIndex)}
          style={{ borderRadius: "18px", overflow: "hidden" }}
          url={videoURL}
          width="100%"
          height={300}
          controls
          muted
          light={isLight}
          playing={isActive || isLight}
        />
      </div>
    </Box>
  );
};

export default Section1;
