import "./section.styles.scss";

import { Box, Button, Container, Grid } from "@mui/material";
import React from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";

const PERSONALIZE =
  "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/personalise.svg";
const COLLABORATE =
  "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/collaborate.svg";
const IMPROVE =
  "https://kewordal-images-dev.s3-eu-west-1.amazonaws.com/assets/kewordal-media/image/improve.svg";
const CREATE_KEWORDAL_LIST = "https://vimeo.com/1054192438/fb6344e7b7";

const Section2 = () => {
  // state
  // const [isPlaying, setIsPlaying] = useState(false);

  // router
  const navigate = useNavigate();

  const handleJoin = () => {
    navigate("/register");
  };

  return (
    <Box className="section-2">
      <div className="top-polygon" />
      <Container sx={{ mt: "-4.5rem" }}>
        <Box
          sx={{
            position: "relative",
            zIndex: 1,
            mb: "4rem",
            display: "flex",
            justifyContent: "center",
            gap: {
              xs: "3rem",
              md: "13rem",
            },
            alignItems: "center",
            flexWrap: "wrap",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              src={PERSONALIZE}
              alt="personalize"
              width="75px"
              height="75px"
              loading="lazy"
            />
            <h3 style={{ fontWeight: 700 }}>Personalise</h3>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              src={COLLABORATE}
              alt="COLLABORATE"
              width="75px"
              height="75px"
              loading="lazy"
            />
            <h3 style={{ fontWeight: 700 }}>Collaborate</h3>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              alignItems: "center",
              gap: "1rem",
            }}
          >
            <img
              src={IMPROVE}
              alt="IMPROVE"
              width="75px"
              height="75px"
              loading="lazy"
            />
            <h3 style={{ fontWeight: 700 }}>Improve</h3>
          </Box>
        </Box>
      </Container>
      <Box sx={{ mb: "-5rem", mt: { xs: "5rem", md: "5rem" } }}>
        <Container maxWidth="xl">
          <Grid
            container
            columnSpacing={{
              xs: 0,
              md: "4rem",
              // lg: "8rem",
              // xl: "5rem",
            }}
            rowSpacing={9}
          >
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  display: "flex",
                  justifyContent: {
                    xs: "center",
                    md: "center",
                  },
                }}
              >
                <Box className="large-circle" />
                <Box
                  className="center"
                  // onMouseEnter={() => setIsPlaying(true)}
                  // onMouseLeave={() => setIsPlaying(false)}
                  sx={{
                    flexGrow: 1,
                    minHeight: "340px",
                    position: "relative",
                    zIndex: 1,
                    borderRadius: "8px",
                    ml: {
                      xs: 0,
                      sm: "4rem",
                    },
                    maxWidth: {
                      xs: "100%",
                      // sm: "600px",
                      // md: "500px",
                      // lg: "600px",
                    },
                  }}
                >
                  <ReactPlayer
                    style={{
                      borderRadius: "15px",
                      overflow: "hidden",
                    }}
                    width="100%"
                    height="100%"
                    controls={true}
                    url={CREATE_KEWORDAL_LIST}
                    light={true}
                    playing
                    // playing={isPlaying}
                    muted
                  />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                sx={{
                  position: "relative",
                  zIndex: 5,
                  height: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  paddingRight: {
                    xs: "2rem",
                    md: "6rem",
                  },
                }}
              >
                <p style={{ fontSize: "1.2rem" }}>
                  Students personalise their learning by building Kewordal
                  lists. Each keyword acts as a store for content which
                  includes, questions, notes, images, links and videos.
                </p>
                <p style={{ fontSize: "1.2rem" }}>
                  Users can opt to be frequently reminded by email of the
                  content they have saved to ensure they have fully absorbed the
                  information by the time they get to a test or an exam.
                </p>
                <p style={{ fontSize: "1.2rem" }}>
                  Similar to flashcards and cue cards, students can collaborate
                  with friends, peers, teachers, and mentors, and build up for
                  themselves a social learning network.
                </p>
                <div>
                  <Button
                    size="medium"
                    variant="contained"
                    onClick={handleJoin}
                    sx={{
                      marginTop: 1,
                      borderRadius: "9px",
                      backgroundColor: "#8AC246",
                      mb: 4,
                      "&:hover": {
                        backgroundColor: "#8AC246",
                      },
                    }}
                  >
                    Join Now
                  </Button>
                </div>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <div className="bottom-polygon" />
    </Box>
  );
};

export default Section2;
